import type { Media } from 'types';
import type { WrapProps } from '@leafwell/components';
import type { PageProps } from 'lib/queries/pages';
import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import camelcaseKeys from 'camelcase-keys';
import { Button, Paragraph, Wrap } from '@leafwell/components';
import SignupForm from 'components/signup-form';
import HeadingBody from 'components/heading-body';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { request } from 'lib/rest';
import { useCurrentState } from 'hooks/useCurrentState';
import {
  getEventDay,
  getEventsMonth,
  hasEvent,
} from 'lib/utils/eventsDateUtils';
import { calculateVpdDiscount } from 'lib/utils/calculateVpdDiscount';

export type HeroCardProps = Partial<Pick<WrapProps, 'className' | 'spacer'>> &
  Pick<
    PageProps['page']['acf'],
    | 'hero'
    | 'states'
    | 'formUrl'
    | 'pageType'
    | 'affiliateLogo'
    | 'affiliateWebsite'
    | 'pauseAppointments'
    | 'redirectMedicalCard'
    | 'redirectCannabisCounseling'
  > & {
    redirectStatePage?: boolean;
    variant?: PageProps['variant'];
    showTrustpilot?: boolean;
    showBadges?: boolean;
    showStateSelect?: boolean;
  };

type PartnerProps = {
  title: string;
  logo: Media;
  website: string;
  couponText?: string;
  event?: {
    uri: string;
    discount: string;
    startDate: string;
    endDate: string;
  };
};

const AffiliateLogo = dynamic(() => import('./affiliate-logo'), {
  loading: () => <div className="h-16"></div>,
  ssr: false,
});

export type BagdeProps = {
  label?: string;
  icon?: Omit<Media, 'srcSet'>;
};

const HeroBadge: React.FC<BagdeProps> = ({ icon, label }) => (
  <article className="flex flex-col gap-y-2 xl:flex-row gap-x-2 items-center">
    <Image
      src={icon.src}
      width={48}
      height={48}
      alt="values header icon"
      quality={50}
      priority={true}
      className="flex h-auto justify-center items-end"
    />
    <Paragraph className="max-w-24 xl:max-w-28	text-xs xl:text-sm font-medium text-center xl:text-left">
      {label}
    </Paragraph>
  </article>
);

const VpdEventAlert: React.FC<PartnerProps['event']> = ({
  startDate,
  endDate,
  discount,
  uri,
}) => {
  const startMonth = getEventsMonth(startDate);
  const endMonth = getEventsMonth(endDate);
  const startDay = getEventDay(startDate);
  const endDay = getEventDay(endDate);
  const dateText =
    startMonth !== endMonth
      ? `${startDay} ${startMonth} and ${endDay} ${endMonth}`
      : `${startDay} and ${endDay} of ${startMonth}`;

  return (
    <>
      <p className="text-center lg:text-left text-white lg:text-black">
        <strong>Save ${discount}</strong> - {dateText} only
      </p>
      <Button
        className="text-xs lg:text-sm min-h-fit !py-2 !px-2"
        rel="noopener"
        label="Sign up here"
        href={uri}
        rounded={true}
        variant="secondary"
        filled={true}
      />
    </>
  );
};

const HeroCardForm: React.FC<
  HeroCardProps & { redirectOnStateChange?: boolean; badges?: BagdeProps[] }
> = ({
  hero,
  badges,
  states,
  formUrl,
  pageType = 'card',
  redirectOnStateChange = true,
  redirectMedicalCard,
  redirectCannabisCounseling,
  affiliateLogo,
  // affiliateWebsite,
}) => {
  const router = useRouter();
  const { query, asPath } = router;
  const [partnerData, setPartnerData] = useState<PartnerProps | null>(null);
  const currentState = useCurrentState(states);

  useEffect(() => {
    if (query.utm_source && !partnerData) {
      // call api to fetch partner data based on `utm_source`
      request(`/partner?slug=${query.utm_source}`).then(res => {
        if (res.data && res.data?.totalItems > 0) {
          const { title, acf } = camelcaseKeys(res.data.items[0], {
            deep: true,
          });
          const { logo, website, couponText, states, vpdPage } = acf;
          let event = null;

          if (vpdPage?.events?.length && vpdPage?.price) {
            const currentEvent = vpdPage.events.filter(
              ({ eventStartDate, eventEndDate }) =>
                hasEvent(eventStartDate, eventEndDate),
            );

            if (currentEvent?.length) {
              const discount = calculateVpdDiscount(
                vpdPage.price?.price,
                vpdPage.price?.newPrice,
              );

              event = {
                uri: vpdPage.uri,
                discount,
                startDate: currentEvent[0]?.eventStartDate,
                endDate: currentEvent[0]?.eventEndDate,
              };
            }
          }

          if (
            !states.length ||
            (states.length && states.includes(currentState))
          ) {
            setPartnerData({
              title,
              logo,
              website,
              couponText,
              event,
            });
          }
        }
      });
    }
  }, [query]);

  useEffect(() => {
    if (query.knowledgebase_id) {
      const wuithoutSearchParams = asPath.split('?')[0];
      const newUrl = `${wuithoutSearchParams}#${query.knowledgebase_id}`;
      router.push(newUrl);
    }
  }, [query]);

  return (
    <>
      {partnerData?.event ? (
        <section className="flex lg:hidden top-[68px] left-0 right-0 fixed z-10 bg-lilac-500 p-3 justify-between sm:justify-center items-center gap-x-2 sm:gap-x-10 drop-in">
          {partnerData.event && <VpdEventAlert {...partnerData.event} />}
        </section>
      ) : null}
      <section className="relative grid bg-lilac-50 xl:-mt-40 xl:pt-40">
        <Wrap
          {...{
            className: [
              'grid lg:grid-cols-2 xl:grid-cols-3 gap-y-32 items-center gap-x-10 py-14',
            ].join(' '),
            spacer: true,
          }}
        >
          <div className="relative flex flex-col xl:col-span-2 gap-y-6 lg:gap-y-8 text-pretty leafwell-mc-bg">
            <HeadingBody
              tagName="h1"
              className="lg:mb-0 font-sans !text-3xl md:!text-4xl lg:!text-5xl xl:!text-7xl text-center lg:text-left text-balance"
              title={hero.title}
            />
            {hero.description?.length > 0 ? (
              <div
                className="text-center lg:text-left"
                dangerouslySetInnerHTML={{ __html: hero.description }}
              ></div>
            ) : null}
            {badges?.length > 0 ? (
              <div
                className={[
                  'flex justify-center lg:justify-start gap-x-6',
                ].join(' ')}
              >
                {badges?.map(({ label, icon }) => {
                  return <HeroBadge key={icon.id} label={label} icon={icon} />;
                })}
              </div>
            ) : null}
            {partnerData ? (
              <div>
                <div
                  className={`lg:inline-flex flex flex-col items-center gap-y-6 mt-6 lg:mt-0 ${
                    partnerData ? 'opacity-1 ' : 'opacity-0'
                  } transition-opacity duration-500
              lg:px-5 lg:py-5 lg:rounded-2xl lg:border lg:border-x lg:radius-20px bg-white border-lilac-100`}
                >
                  <div className="flex flex-col md:flex-row justify-center gap-6">
                    {partnerData?.logo ? (
                      <div className="self-center justify-self-center">
                        <AffiliateLogo
                          affiliateLogo={affiliateLogo || partnerData.logo}
                          // we won't link to the partner while we don't have their links
                          // affiliateWebsite={affiliateWebsite || partnerData.website}
                        />
                      </div>
                    ) : null}
                    <div className="flex flex-col justify-center text-center md:text-left gap-y-4">
                      <p className="text text-pretty text-lg">
                        Leafwell is proud to partner <br />
                        with <strong>{partnerData.title}</strong>
                      </p>
                      {partnerData?.couponText ? (
                        <div
                          className="inline-block"
                          dangerouslySetInnerHTML={{
                            __html: partnerData.couponText,
                          }}
                        />
                      ) : null}
                    </div>
                  </div>
                  {partnerData?.event && (
                    <div className="hidden lg:flex flex-col items-center gap-y-6 w-full">
                      <hr className="w-full border-lilac-100" />
                      <div className="flex flex-col gap-y-4">
                        <VpdEventAlert {...partnerData.event} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
          <div className="w-full max-w-md justify-self-center lg:justify-self-end">
            <SignupForm
              formUrl={formUrl}
              pageType={pageType}
              activeStates={states}
              redirectMedicalCard={redirectMedicalCard}
              redirectOnStateChange={redirectOnStateChange}
              redirectCannabisCounseling={redirectCannabisCounseling}
              formSubmitText={hero.formSubmitText}
            />
            <div className="mt-4 text-center">
              <p className="text-sm">
                Already a Leafwell patient?
                <br />
                <a
                  className="font-medium underline underline-offset-3"
                  target="_blank"
                  href={process.env.NEXT_PUBLIC_LOGIN_URL}
                >
                  Log in here
                </a>
              </p>
            </div>
          </div>
        </Wrap>
      </section>
    </>
  );
};

const HeroCard: React.FC<HeroCardProps> = props => {
  const { disableMedicalCard, translations } = useWordPressContext();
  const {
    redirectStatePage,
    affiliateLogo,
    pauseAppointments,
    redirectMedicalCard,
  } = props;

  let redirectOnStateChange = true;

  if (redirectStatePage) {
    redirectOnStateChange = redirectStatePage;
  }

  if (disableMedicalCard) {
    redirectOnStateChange = false;
  }

  // this is an affiliate page
  if (affiliateLogo) {
    redirectOnStateChange = false;
  }

  // this page isn't active on medical card and a hubspot form is displayed
  if (pauseAppointments || !redirectMedicalCard) {
    redirectOnStateChange = false;
  }

  const badges = [
    {
      label: translations['Best Care, Best Value'],
      icon: {
        alt: 'Best Care, Best Value',
        title: 'diamond-solid',
        id: '1',
        src: '/diamond-solid.svg',
        srcSet: null,
        width: 18,
        height: 18,
      },
    },
    {
      label: translations['Quick and Easy Process'],
      icon: {
        alt: 'Quick and Easy Process',
        title: 'clock-solid',
        id: '2',
        src: '/clock-solid.svg',
        srcSet: null,
        width: 18,
        height: 18,
      },
    },
    {
      label: translations['Get Approved or Your Money Back'],
      icon: {
        alt: 'Get Approved or Your Money Back',
        title: 'hand-dollar-solid',
        id: '3',
        src: '/hand-dollar-solid.svg',
        srcSet: null,
        width: 18,
        height: 18,
      },
    },
  ];

  return (
    <HeroCardForm
      badges={badges}
      redirectMedicalCard={redirectMedicalCard}
      redirectOnStateChange={redirectOnStateChange}
      {...props}
    />
  );
};

export default HeroCard;
