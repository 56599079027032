import type { Page } from 'types';
import type { BlockSignupForm } from 'types/block-signup-form';
import dynamic from 'next/dynamic';
import { marketingForms } from 'components/marketing-form';
import type { UtmParamsOptional } from 'hooks/useCurrentUtm';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useCurrentState } from 'hooks/useCurrentState';

const MarketingForm = dynamic(() => import('./marketing-form'));
const SignupFormTelehealth = dynamic(() => import('./signup-form-telehealth'));

export type SignupFormProps = Pick<
  Page['acf'],
  'pageType' | 'redirectMedicalCard' | 'redirectCannabisCounseling'
> & {
  formUrl?: BlockSignupForm['formUrl'];
  formType?: BlockSignupForm['formType'];
  subTitle?: BlockSignupForm['subTitle'];
  formSubmitText?: string;
  activeStates?: string[];
  formTitle?: string;
  inputEmail?: string;
  isRenewal?: boolean;
  customStateCode?: string;
  pauseAppointments?: boolean;
  readOnlyStateField?: boolean;
  totalMedicalCardPrice?: string;
  hasReferral?: boolean;
  redirectOnStateChange?: boolean;
  utmParams?: UtmParamsOptional;
};

export type SignupFormBlockProps = Omit<BlockSignupForm, 'acfFcLayout'> &
  Omit<SignupFormProps, 'hero'> & {
    affiliateLogo?: Page['acf']['affiliateLogo'];
    affiliateWebsite?: Page['acf']['affiliateWebsite'];
  };

const SignupForm: React.FC<SignupFormProps> = ({
  activeStates,
  formUrl,
  pageType,
  hasReferral = true,
  pauseAppointments = false,
  redirectMedicalCard = false,
  customStateCode,
  isRenewal,
  redirectCannabisCounseling = false,
  redirectOnStateChange,
  readOnlyStateField = false,
  utmParams,
  formTitle,
  subTitle,
  formType,
  inputEmail,
  totalMedicalCardPrice,
  formSubmitText = 'Get Started',
}) => {
  const { disableMedicalCard, translations } = useWordPressContext();
  const pageState = customStateCode
    ? customStateCode
    : useCurrentState(activeStates);

  formType = formType
    ? formType
    : pageType === 'cannabis_counseling'
    ? 'counseling'
    : 'card';

  const isCardPage = pageType === 'card';
  let label =
    formTitle !== null
      ? formTitle
      : isCardPage
      ? translations['Apply now']
      : translations['Book a Session'];
  let isMCSignupForm = true;
  let formName = '';

  if (pageType !== 'cannabis_counseling') {
    if (
      (!isCardPage && disableMedicalCard) ||
      (isCardPage && disableMedicalCard && redirectMedicalCard) ||
      pauseAppointments
    ) {
      isMCSignupForm = false;
      label = translations['Start your registration'];
      formName = marketingForms.signupPaused;
    }

    if (isCardPage && !redirectMedicalCard) {
      isMCSignupForm = false;
      label = translations['Join the waitlist'];
      formName = marketingForms.waitinglistMC;
    }
  } else {
    if (!redirectCannabisCounseling) {
      isMCSignupForm = false;
      label = translations['Join the waitlist'];
      formName = marketingForms.waitinglistCG;
    }
  }

  return (
    <div
      id="leafwell-signup-form"
      className={['relative flex flex-col'].join(' ')}
    >
      {isMCSignupForm ? (
        <SignupFormTelehealth
          src={formUrl}
          utmParams={utmParams}
          stateCode={formType === 'card' ? pageState : 'cannacon'} // Medical Card states (could be `null`) or Cannabis Guidance
          isCardForm={formType === 'card'}
          redirectOnStateChange={redirectOnStateChange}
          isRenewal={isRenewal}
          readOnlyStateField={readOnlyStateField}
          inputEmail={inputEmail}
          totalMedicalCardPrice={totalMedicalCardPrice}
          subTitle={subTitle}
          hasCustomStateCode={customStateCode ? true : false}
          pauseAppointments={pauseAppointments}
          hasReferral={hasReferral}
          formSubmitText={formSubmitText}
        />
      ) : (
        <MarketingForm
          label={label}
          formName={formName}
          pageType={pageType}
          utmParams={utmParams}
          stateCode={formType === 'card' ? pageState : 'cannacon'}
        />
      )}
    </div>
  );
};

export default SignupForm;
